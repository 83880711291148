import _acorn from "acorn";
var exports = {};
var tt = _acorn.tokTypes;
var skipWhiteSpace = /(?:\s|\/\/.*|\/\*[^]*?\*\/)*/g;

var nextTokenIsDot = function (parser) {
  skipWhiteSpace.lastIndex = parser.pos;
  var skip = skipWhiteSpace.exec(parser.input);
  var next = parser.pos + skip[0].length;
  return parser.input.slice(next, next + 1) === ".";
};

exports = function (Parser) {
  return /*@__PURE__*/function (Parser) {
    function anonymous() {
      Parser.apply(this, arguments);
    }

    if (Parser) anonymous.__proto__ = Parser;
    anonymous.prototype = Object.create(Parser && Parser.prototype);
    anonymous.prototype.constructor = anonymous;

    anonymous.prototype.parseExprAtom = function parseExprAtom(refDestructuringErrors) {
      if (this.type !== tt._import || !nextTokenIsDot(this)) {
        return Parser.prototype.parseExprAtom.call(this, refDestructuringErrors);
      }

      if (!this.options.allowImportExportEverywhere && !this.inModule) {
        this.raise(this.start, "'import' and 'export' may appear only with 'sourceType: module'");
      }

      var node = this.startNode();
      node.meta = this.parseIdent(true);
      this.expect(tt.dot);
      node.property = this.parseIdent(true);

      if (node.property.name !== "meta") {
        this.raiseRecoverable(node.property.start, "The only valid meta property for import is import.meta");
      }

      return this.finishNode(node, "MetaProperty");
    };

    anonymous.prototype.parseStatement = function parseStatement(context, topLevel, exports) {
      if (this.type !== tt._import || !nextTokenIsDot(this)) {
        return Parser.prototype.parseStatement.call(this, context, topLevel, exports);
      }

      var node = this.startNode();
      var expr = this.parseExpression();
      return this.parseExpressionStatement(node, expr);
    };

    return anonymous;
  }(Parser);
};

export default exports;