import _acorn from "acorn";
var exports = {};
var skipWhiteSpace = /(?:\s|\/\/.*|\/\*[^]*?\*\/)*/g;
var tt = _acorn.tokTypes;

exports = function (Parser) {
  return /*@__PURE__*/function (Parser) {
    function anonymous() {
      Parser.apply(this, arguments);
    }

    if (Parser) anonymous.__proto__ = Parser;
    anonymous.prototype = Object.create(Parser && Parser.prototype);
    anonymous.prototype.constructor = anonymous;

    anonymous.prototype.parseExport = function parseExport(node, exports) {
      skipWhiteSpace.lastIndex = this.pos;
      var skip = skipWhiteSpace.exec(this.input);
      var next = this.input.charAt(this.pos + skip[0].length);

      if (next !== "*") {
        return Parser.prototype.parseExport.call(this, node, exports);
      }

      this.next();
      var specifier = this.startNode();
      this.expect(tt.star);

      if (this.eatContextual("as")) {
        node.declaration = null;
        specifier.exported = this.parseIdent(true);
        this.checkExport(exports, specifier.exported.name, this.lastTokStart);
        node.specifiers = [this.finishNode(specifier, "ExportNamespaceSpecifier")];
      }

      this.expectContextual("from");

      if (this.type !== tt.string) {
        this.unexpected();
      }

      node.source = this.parseExprAtom();
      this.semicolon();
      return this.finishNode(node, node.specifiers ? "ExportNamedDeclaration" : "ExportAllDeclaration");
    };

    return anonymous;
  }(Parser);
};

export default exports;