import _acorn from "acorn";
import _privateClassElements from "../private-class-elements";
var exports = {};
var skipWhiteSpace = /(?:\s|\/\/.*|\/\*[^]*?\*\/)*/g;
var acorn = _acorn;
var tt = acorn.tokTypes;

function maybeParseFieldValue(field) {
  if (this.eat(tt.eq)) {
    var oldInFieldValue = this._inStaticFieldValue;
    this._inStaticFieldValue = true;
    field.value = this.parseExpression();
    this._inStaticFieldValue = oldInFieldValue;
  } else {
    field.value = null;
  }
}

var privateClassElements = _privateClassElements;

exports = function (Parser) {
  var ExtendedParser = privateClassElements(Parser);
  return /*@__PURE__*/function (ExtendedParser) {
    function anonymous() {
      ExtendedParser.apply(this, arguments);
    }

    if (ExtendedParser) anonymous.__proto__ = ExtendedParser;
    anonymous.prototype = Object.create(ExtendedParser && ExtendedParser.prototype);
    anonymous.prototype.constructor = anonymous;

    anonymous.prototype.parseClassElement = function parseClassElement(_constructorAllowsSuper) {
      var this$1 = this;

      if (this.eat(tt.semi)) {
        return null;
      }

      var node = this.startNode();

      var tryContextual = function (k, noLineBreak) {
        if (typeof noLineBreak == "undefined") {
          noLineBreak = false;
        }

        var start = this$1.start,
            startLoc = this$1.startLoc;

        if (!this$1.eatContextual(k)) {
          return false;
        }

        if (this$1.type !== tt.parenL && (!noLineBreak || !this$1.canInsertSemicolon())) {
          return true;
        }

        if (node.key) {
          this$1.unexpected();
        }

        node.computed = false;
        node.key = this$1.startNodeAt(start, startLoc);
        node.key.name = k;
        this$1.finishNode(node.key, "Identifier");
        return false;
      };

      node.static = tryContextual("static");

      if (!node.static) {
        return ExtendedParser.prototype.parseClassElement.apply(this, arguments);
      }

      var isGenerator = this.eat(tt.star);
      var isAsync = false;

      if (!isGenerator) {
        // Special-case for `async`, since `parseClassMember` currently looks
        // for `(` to determine whether `async` is a method name
        if (this.options.ecmaVersion >= 8 && this.isContextual("async")) {
          skipWhiteSpace.lastIndex = this.pos;
          var skip = skipWhiteSpace.exec(this.input);
          var next = this.input.charAt(this.pos + skip[0].length);

          if (next === ";" || next === "=") {
            node.key = this.parseIdent(true);
            node.computed = false;
            maybeParseFieldValue.call(this, node);
            this.finishNode(node, "FieldDefinition");
            this.semicolon();
            return node;
          } else if (this.options.ecmaVersion >= 8 && tryContextual("async", true)) {
            isAsync = true;
            isGenerator = this.options.ecmaVersion >= 9 && this.eat(tt.star);
          }
        } else if (tryContextual("get")) {
          node.kind = "get";
        } else if (tryContextual("set")) {
          node.kind = "set";
        }
      }

      if (this.type === this.privateNameToken) {
        this.parsePrivateClassElementName(node);

        if (this.type !== tt.parenL) {
          if (node.key.name === "prototype") {
            this.raise(node.key.start, "Classes may not have a private static property named prototype");
          }

          maybeParseFieldValue.call(this, node);
          this.finishNode(node, "FieldDefinition");
          this.semicolon();
          return node;
        }
      } else if (!node.key) {
        this.parsePropertyName(node);

        if ((node.key.name || node.key.value) === "prototype" && !node.computed) {
          this.raise(node.key.start, "Classes may not have a static property named prototype");
        }
      }

      if (!node.kind) {
        node.kind = "method";
      }

      this.parseClassMethod(node, isGenerator, isAsync);

      if (!node.kind && (node.key.name || node.key.value) === "constructor" && !node.computed) {
        this.raise(node.key.start, "Classes may not have a static field named constructor");
      }

      if (node.kind === "get" && node.value.params.length !== 0) {
        this.raiseRecoverable(node.value.start, "getter should have no params");
      }

      if (node.kind === "set" && node.value.params.length !== 1) {
        this.raiseRecoverable(node.value.start, "setter should have exactly one param");
      }

      if (node.kind === "set" && node.value.params[0].type === "RestElement") {
        this.raiseRecoverable(node.value.params[0].start, "Setter cannot use rest params");
      }

      return node;
    }; // Parse public static fields


    anonymous.prototype.parseClassMethod = function parseClassMethod(method, isGenerator, isAsync, _allowsDirectSuper) {
      if (isGenerator || isAsync || method.kind != "method" || !method.static || this.options.ecmaVersion < 8 || this.type == tt.parenL) {
        return ExtendedParser.prototype.parseClassMethod.apply(this, arguments);
      }

      maybeParseFieldValue.call(this, method);
      delete method.kind;
      method = this.finishNode(method, "FieldDefinition");
      this.semicolon();
      return method;
    }; // Prohibit arguments in class field initializers


    anonymous.prototype.parseIdent = function parseIdent(liberal, isBinding) {
      var ident = ExtendedParser.prototype.parseIdent.call(this, liberal, isBinding);

      if (this._inStaticFieldValue && ident.name == "arguments") {
        this.raise(ident.start, "A static class field initializer may not contain arguments");
      }

      return ident;
    };

    return anonymous;
  }(ExtendedParser);
};

export default exports;