import _acorn from "acorn";
import _privateClassElements from "../private-class-elements";
var exports = {};
var acorn = _acorn;
var tt = acorn.tokTypes;
var privateClassElements = _privateClassElements;

function maybeParseFieldValue(field) {
  if (this.eat(tt.eq)) {
    var oldInFieldValue = this._inFieldValue;
    this._inFieldValue = true;
    field.value = this.parseExpression();
    this._inFieldValue = oldInFieldValue;
  } else {
    field.value = null;
  }
}

exports = function (Parser) {
  Parser = privateClassElements(Parser);
  return /*@__PURE__*/function (Parser) {
    function anonymous() {
      Parser.apply(this, arguments);
    }

    if (Parser) anonymous.__proto__ = Parser;
    anonymous.prototype = Object.create(Parser && Parser.prototype);
    anonymous.prototype.constructor = anonymous;

    anonymous.prototype.parseClassElement = function parseClassElement(_constructorAllowsSuper) {
      if (this.options.ecmaVersion >= 8 && (this.type == tt.name || this.type == this.privateNameToken || this.type == tt.bracketL || this.type == tt.string)) {
        var branch = this._branch();

        if (branch.type == tt.bracketL) {
          var count = 0;

          do {
            if (branch.eat(tt.bracketL)) {
              ++count;
            } else if (branch.eat(tt.bracketR)) {
              --count;
            } else {
              branch.next();
            }
          } while (count > 0);
        } else {
          branch.next();
        }

        if (branch.type == tt.eq || branch.canInsertSemicolon() || branch.type == tt.semi) {
          var node = this.startNode();

          if (this.type == this.privateNameToken) {
            this.parsePrivateClassElementName(node);
          } else {
            this.parsePropertyName(node);
          }

          if (node.key.type === "Identifier" && node.key.name === "constructor" || node.key.type === "Literal" && node.key.value === "constructor") {
            this.raise(node.key.start, "Classes may not have a field called constructor");
          }

          maybeParseFieldValue.call(this, node);
          this.finishNode(node, "FieldDefinition");
          this.semicolon();
          return node;
        }
      }

      return Parser.prototype.parseClassElement.apply(this, arguments);
    }; // Prohibit arguments in class field initializers


    anonymous.prototype.parseIdent = function parseIdent(liberal, isBinding) {
      var ident = Parser.prototype.parseIdent.call(this, liberal, isBinding);

      if (this._inFieldValue && ident.name == "arguments") {
        this.raise(ident.start, "A class field initializer may not contain arguments");
      }

      return ident;
    };

    return anonymous;
  }(Parser);
};

export default exports;