import _acorn from "acorn";
import _xtend from "xtend";
import _bigint from "./lib/bigint";
import _classFields from "./lib/class-fields";
import _staticClassFeatures from "./lib/static-class-features";
import _numericSeparator from "./lib/numeric-separator";
import _dynamicImport from "./lib/dynamic-import";
import _exportNsFrom from "./lib/export-ns-from";
import _importMeta from "./lib/import-meta";
var exports = {};
var acorn = _acorn;
var xtend = _xtend;
var CJSParser = acorn.Parser.extend(_bigint).extend(_classFields).extend(_staticClassFeatures).extend(_numericSeparator).extend(_dynamicImport.default);
var ESModulesParser = CJSParser.extend(_exportNsFrom).extend(_importMeta);

function mapOptions(opts) {
  if (!opts) opts = {};
  return xtend({
    ecmaVersion: 2020,
    allowHashBang: true,
    allowReturnOutsideFunction: true
  }, opts);
}

function getParser(opts) {
  if (!opts) opts = {};
  return opts.sourceType === "module" ? ESModulesParser : CJSParser;
}

exports = exports = xtend(acorn, {
  parse: function parse(src, opts) {
    return getParser(opts).parse(src, mapOptions(opts));
  },
  parseExpressionAt: function parseExpressionAt(src, offset, opts) {
    return getParser(opts).parseExpressionAt(src, offset, mapOptions(opts));
  },
  tokenizer: function tokenizer(src, opts) {
    return getParser(opts).tokenizer(src, mapOptions(opts));
  }
});
export default exports;